/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'
import cn from 'classnames'
import { Trans } from 'next-i18next'

import { Image } from 'ui/image'

import { ExposureTracker } from 'components/exposure-tracker'
import { Container } from 'components/container'
import { DynamicComponentProps } from 'components/dynamic-components/types'

import useHeader from 'lib/hooks/useHeader'
import useDateCurrency from 'lib/hooks/useDateCurrency'

import { EVENTS } from 'lib/constants/events'

import { SearchBar } from './search-bar'
import { ActivityTagCards } from './activity-tag-cards'

import s from './styles.module.scss'

interface HeroProps extends DynamicComponentProps {
  trackEvent?: TrackEventType
  id: 'hero'
}

// const HERO_IMAGE_ROTATION = 6000
// const DELAY_HERO_IMAGE_LOAD = HERO_IMAGE_ROTATION - 2000

const Hero = ({ componentEventId, componentContent, trackEvent, id }: HeroProps) => {
  const { images } = componentContent

  const [activeHeroIndex] = React.useState(() => {
    return Math.floor(Math.random() * images.length)
  })

  // const initialIndexRef = useRef(activeHeroIndex)

  const { formatNumber } = useDateCurrency()

  const { setWayPoint: searchInputWayPoint } = useHeader({
    mode: 'searchVisibility',
    defaultValue: false,
  })

  const { setWayPoint: scrollStartWayPoint } = useHeader({
    mode: 'transparency',
    defaultValue: true,
  })

  // Commented out the code for image carousel for now
  // TODO: Yanto - Uncomment this once can identify how to improve the performance
  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     setActiveHeroIndex((curr) => (curr + 1) % images?.length)
  //   }, HERO_IMAGE_ROTATION)

  //   return () => clearTimeout(timeoutId)
  // }, [activeHeroIndex, images.length])

  // const getDelayLoadValue = (index: number) => {
  //   if (!initialIndexRef.current && initialIndexRef.current === index) {
  //     return undefined
  //   }

  //   const lastIndex = images.length - 1
  //   let nextIndex = index + 1
  //   if (index < initialIndexRef.current) {
  //     const incrementValue = lastIndex - initialIndexRef.current + index + 1
  //     nextIndex = incrementValue
  //   } else {
  //     nextIndex = index - initialIndexRef.current
  //   }

  //   return DELAY_HERO_IMAGE_LOAD * nextIndex
  // }

  const image = images[activeHeroIndex]

  return (
    <div id={id} className={cn(s.container)}>
      <div ref={scrollStartWayPoint} className={s.scrollStartWayPoint} />

      <div className={s.heroImage}>
        <Image
          src={image}
          layout="fill"
          className={cn({ [s.active]: true })}
          alt="hero image"
          lazyLoad={false}
          priority
        />
        {/* {images.map((image: MediaItem, index: number) => {
          const lazyLoad = index !== initialIndexRef.current
          const delayLoad = getDelayLoadValue(index)

          return (
            <Image
              key={index}
              src={image}
              layout="fill"
              alt="hero image"
              lazyLoad={lazyLoad}
              delayLoad={delayLoad}
              className={cn({ [s.active]: activeHeroIndex === index })}
            />
          )
        })} */}
        <div className={s.gradient} />
      </div>

      <Container className={s.body}>
        <div className={s.searchInputWrapper}>
          <SearchBar trackEvent={trackEvent} />
        </div>
        <div className={s.headerSection}>
          <h1 className={s.header}>
            <Trans
              ns="common"
              i18nKey="msg.bookNumOfActivitiesWorldwide"
              values={{
                num: formatNumber(200000),
              }}
              components={{
                span: <span></span>,
              }}
            />
          </h1>
          <ActivityTagCards trackEvent={trackEvent!} />
        </div>
      </Container>
      <div ref={searchInputWayPoint} className={s.searchInputWayPoint} />
      <ExposureTracker
        onExposure={() => {
          trackEvent?.({
            attributeId: componentEventId,
            attributeType: EVENTS.ATTRIBUTES_TYPE.SECTION,
            eventType: EVENTS.TYPE.EXPOSURE,
          })
        }}
      />
    </div>
  )
}

export { Hero }
