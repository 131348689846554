import React, { ReactElement, useCallback } from 'react'
import { GetServerSideProps } from 'next'
import cookie from 'cookie'

import { CardSlider } from 'components/card-slider'
import { DynamicComponents } from 'components/dynamic-components'
import MainLayout from 'components/layout/main-layout'
import { MetaTags } from 'components/meta-tags'
import { PinnedReviews } from 'components/pinned-reviews'
import { PopularProducts } from 'components/popular-products'
import { PromoBanner } from 'components/promo-banner'
import { PromoNotification } from 'components/promo-notification'
import { ResponsiveSliderGrid } from 'components/responsive-slider-grid'
import { RewardsBanner } from 'components/rewards-banner'
import { WhatIsPopular } from 'components/what-is-popular'
import { WhyBookWithPelago } from 'components/why-book-with-pelago'
import type { DynamicComponentProps } from 'components/dynamic-components/types'
import { DestinationsByRegion } from 'components/destinations-by-region'
import { Destinations } from 'components/destinations-list'
import { DestinationModal } from 'components/destination-modal'
import { OngoingDeals } from 'components/ongoing-deals'
import { ExclusiveBenefits } from 'components/exclusive-benefits'

import { Hero } from 'page-modules/home/hero'

import usePageTrackEvent from 'lib/hooks/usePageTrackEvent'
import useTracking from 'lib/hooks/useTracking'
import useWishlist from 'lib/hooks/useWishlist'
import { useToggle } from 'lib/hooks/useToggle'

import { DynamicComponentPageProps } from 'lib/@Types'
import { useGlobalContext } from 'lib/context/global-context'
import { dynamicComponentQuery, serverSideTranslations } from 'lib/utils/server'

import { COOKIES_DS_USER_ID, COOKIES_RECENT_PDP_VISIT } from 'lib/constants'
import { EVENTS } from 'lib/constants/events'

import s from './styles.module.scss'

const Home = ({ components }: DynamicComponentPageProps) => {
  useWishlist()

  const { isMobileView } = useGlobalContext()
  const { trackEvent } = useTracking({
    pageName: EVENTS.HOME,
    pageMainAttributeType: EVENTS.HOME,
  })
  usePageTrackEvent({ trackEvent })

  const [isDestModalOpen, { onOpen, onClose }] = useToggle(false)

  const renderPopularProducts = useCallback((props: DynamicComponentProps) => {
    return <PopularProducts {...props} enableDestinationModal />
  }, [])

  const renderDestinationByRegion = useCallback(
    (props: DynamicComponentProps) => {
      return <DestinationsByRegion {...props} trackEvent={trackEvent} />
    },
    [trackEvent]
  )

  return (
    <>
      <DynamicComponents
        mapping={{
          popularProductsComponent: renderPopularProducts,
          metaTagsComponent: MetaTags,
          firstFoldComponent: <Hero id="hero" />,
          gridSliderComponent: ResponsiveSliderGrid,
          productSliderComponent: CardSlider,
          destinationComponent: (
            <Destinations onButtonClick={onOpen} size={isMobileView ? 'medium' : 'large'} />
          ),
          destinationByRegionComponent: renderDestinationByRegion,
          promoBannerComponent: (
            <PromoBanner trackEvent={trackEvent} variant={isMobileView ? undefined : 'container'} />
          ),
          rewardsBannerComponent: (
            <RewardsBanner trackEvent={trackEvent} variant={isMobileView ? undefined : 'container'} />
          ),
          ongoingDealsComponent: <OngoingDeals trackEvent={trackEvent} />,
          exclusiveBenefitsComponent: <ExclusiveBenefits trackEvent={trackEvent} />,
          reviewSliderComponent: PinnedReviews,
          globalSeoComponent: WhatIsPopular,
          staticStoryCardsComponent: WhyBookWithPelago,
        }}
        components={components}
        pageName="home"
        trackEvent={trackEvent}
      />
      <DestinationModal
        trackEvent={trackEvent}
        open={isDestModalOpen}
        closeModal={onClose}
        shouldAutoFocus={!isMobileView}
      />
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { locale, req } = context
  const { cookie: reqHeaderCookies } = req?.headers || {}

  const reqCookies = cookie.parse(reqHeaderCookies || '')
  const isRecentlyViewedSection = reqCookies[COOKIES_RECENT_PDP_VISIT] === 'true'
  // if dsUserId not exist in req cookies, it means user is new
  const isNewUser = !reqCookies[COOKIES_DS_USER_ID]

  // as client has not visited any PDP(product), no need to show recently viewed(product) section
  const componentToExclude = isRecentlyViewedSection
    ? undefined
    : { byKey: 'componentConfig.config.graphqlOperation', byValue: 'recentlyViewed' }

  const { components } = await dynamicComponentQuery(
    context,
    { variables: { dynamicComponentsInput: { page: 'homepage', isNewUser } } },
    componentToExclude
  )

  return {
    props: {
      components,
      ...(await serverSideTranslations(locale, 'HOME')),
    },
  }
}

Home.getLayout = function getLayout(page: ReactElement) {
  return (
    <MainLayout hasSearch additionalParams={{ isHomePage: true }} notification={<PromoNotification />}>
      <MainLayout.Content whiteBg className={s.container}>
        {page}
      </MainLayout.Content>
    </MainLayout>
  )
}

export default Home
